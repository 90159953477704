@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-smoothing: antialiased;
  word-break: break-word;
  font-family: Roboto, sans-serif;
  font-variant-numeric: lining-nums;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.4285em;
}

a {
  color: #0778bc;
  text-decoration: none;

  &:hover {
    color: #41c0eb;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}
